import axios from "axios";

const token_value = localStorage.getItem("accessToken");

const setAuthToken = (token) => {
  if (token_value) {
    // Apply to every request
    axios.defaults.headers.common["Authorization"] = `Bearer ${token_value}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
