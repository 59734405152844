import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Tooltip,
  Drawer,
  Popover,
} from "antd";
import axios from "axios";
import { API_URL } from "../actions/urlConfig";
import {
  MdShoppingCart,
  MdAccountCircle,
  MdSearch,
  MdMoreVert,
  MdClose,
} from "react-icons/md";
import "./search_bar.css";
import SearchModal from "./SearchModal";
import { RiMenu2Line } from "react-icons/ri";

const token = localStorage.getItem("accessToken");

class HeaderCustomer extends Component {
  state = {
    dis: "block",
    carData: [],
    defaultCar: [],
    cart: 0,
    searching: false,
    visible: false,
    visible2: false,
    userDetail: null,
    loading: true,
  };
  componentDidMount = () => {
    if (token) {
      let userDetail = JSON.parse(localStorage.automotoUserData);
      console.log("user data details", userDetail);
      this.setState({ userDetail });
    }

    // console.log(pathname, "location");
    axios.get(`${API_URL}/vehicles/vehicle-list/`).then((res) => {
      console.log(res, "car data321");
      this.setState({ carData: res.data.data, loading: false });
    });
    axios.get(`${API_URL}/vehicles/vehicle-default-list/`).then((res) => {
      console.log(res, "car data123 ");
      this.setState({ defaultCar: res.data.data });
    });
    axios.get(`${API_URL}/users/profile-view/customer/`).then((res) => {
      this.setState({ person: res.data.data });
      localStorage.setItem("automotoUserData", JSON.stringify(res.data.data));
    });
    axios.get(`${API_URL}/carts/view-cart/`).then((res) => {
      console.log("cart items", res.data.data.length);
      this.setState({ cart: res?.data?.data?.length });
    });
  };
  changeDefault = (id) => {
    axios.get(`${API_URL}/vehicles/set-default/${id}`).then((res) => {
      this.componentDidMount();
      console.log(res);
      window.location.reload();
      message.success("Default vehicle changed");
    });
  };
  showDrawer = () =>
    this.setState({
      visible: true,
    });
  showDrawer2 = () =>
    this.setState({
      visible2: true,
    });
  onClose = () =>
    this.setState({
      visible: false,
    });
  onClose2 = () =>
    this.setState({
      visible2: false,
    });
  render() {
    document.addEventListener("datavalnew", () => {
      axios.get(`${API_URL}/carts/view-cart/`).then((res) => {
        console.log("cart items", res.data.data.length);
        this.setState({ cart: res?.data?.data?.length });
      });
    });

    return (
      <div className="headerstore">
        <div className="headerstore-content">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/home" className="headerstore-firstlogo">
              <img alt="logo" src={require("../assets/moto365logo.svg")}></img>
            </Link>
            <button onClick={this.showDrawer} className="mob_more">
              <RiMenu2Line />
            </button>

            {/* <button className="location">
              <img
                src={location}
                alt="location"
                style={{ width: "20px", height: "20px" }}
              ></img>
              <h6>Kochi</h6>
            </button> */}
          </div>

          <div style={{ display: "flex", position: "relative" }}>
            <div className="nav_right">
              {token !== null && (
                <div className="car-detail" style={{ display: "flex" }}>
                  <Dropdown
                    overlay={
                      <Menu>
                        {this.state.carData?.map((res, index) => (
                          <Menu.Item
                            onClick={() =>
                             
                              this.changeDefault(res.id)
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h6 className="carnames">{res.vehicle_model}</h6>
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {!this.state.loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "4px",
                          }}
                        >
                          <img
                            src={this.state.defaultCar?.imageURL}
                            alt="car"
                            className="car_image_header"
                          ></img>
                          <h6 className="carnames">
                            {this.state.defaultCar?.vehicle_model}
                          </h6>
                        </div>
                      ) : this.state.carData.length !== 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "4px",
                          }}
                        >
                          <img
                            alt="car"
                            src={require("../assets/moto365logo.svg")}
                            className="car_image_header"
                          ></img>
                          <h6 className="carnames">Loading...</h6>
                        </div>
                      ) : (
                        <Link to="/vehicle-main">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              alt="car"
                              src={require("../assets/moto365logo.svg")}
                              className="car_image_header"
                            ></img>
                            <h6 className="carnames">Add vehicle</h6>
                          </div>
                        </Link>
                      )}
                    </a>
                  </Dropdown>
                  <Link activeClassName="navbar-active-now" to="/cart">
                    <Tooltip placement="bottom" title={"Cart"}>
                      <Button>
                        <Badge
                          count={
                            this.props.cartValue
                              ? this.props.cartValue
                              : this.state.cart
                          }
                        >
                          <MdShoppingCart />
                        </Badge>
                      </Button>
                    </Tooltip>
                  </Link>
                </div>
              )}
              <div
                className="contactsTop1"
                style={{ justifyContent: "space-between" }}
              >
                <Button onClick={() => this.setState({ searching: true })}>
                  <MdSearch style={{ fontSize: "22px" }} />
                </Button>
              </div>
              <span className="accnt_main_nav">
                {token ? (
                  <Popover
                    placement="bottomRight"
                    content={
                      <div className="navbar_account">
                        <span>
                          <img
                            alt="car"
                            src={this.state.userDetail?.photo}
                          ></img>
                          <p>{this.state.userDetail?.name}</p>
                        </span>
                        <div className="navbar_account_content">
                          <Link to="/forum/create/new">
                            <p>Create a post</p>
                          </Link>
                          <Link to="/account">
                            <p>Profile settings</p>
                          </Link>
                          <p
                            onClick={() => {
                              localStorage.clear();
                              window.location.reload();
                            }}
                          >
                            Sign out
                          </p>
                        </div>
                      </div>
                    }
                    title="Title"
                    trigger="click"
                  >
                    <Button>
                      <MdAccountCircle style={{ fontSize: "22px" }} />
                    </Button>
                  </Popover>
                ) : (
                  <Link activeClassName="navbar-active-now" to="/sign-in">
                    <Tooltip placement="bottom" title={"Sign in"}>
                      <Button>
                        <MdAccountCircle style={{ fontSize: "22px" }} />
                      </Button>
                    </Tooltip>
                  </Link>
                )}
              </span>
              {token && (
                <div className="mob_more_account">
                  <Button onClick={this.showDrawer2}>
                    <MdMoreVert style={{ fontSize: "22px" }} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          title=""
          visible={this.state.searching}
          // onOk={handleOk}
          onCancel={() => this.setState({ searching: false })}
          width="1200px"
          id="search_screen_main"
        >
          <SearchModal />
        </Modal>
        <Drawer
          placement={"top"}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          key={"top"}
          id="drawer_wrapper"
        >
          <div className="drawer_close_icon" onClick={this.onClose}>
            <MdClose />
          </div>
          <div className="top_drawer_home">
            <ul>
              <Link to="/home">
                <li>Home</li>
              </Link>
              <Link to="/service-categories">
                <li>Services</li>
              </Link>
              <Link to="/productslist">
                <li>Products</li>
              </Link>
              <Link to="search-vehicle">
                <li>Auto compare</li>
              </Link>
            </ul>
            <div className="top_left_drawer_logo">
              <img alt="logo" src={require("../assets/moto365logo.svg")}></img>
            </div>
          </div>
        </Drawer>
        <Drawer
          placement={"right"}
          closable={false}
          onClose={this.onClose2}
          visible={this.state.visible2}
          key={"right"}
          id="drawer_wrapper_right"
          size="default"
        >
          <div className="drawer_close_icon" onClick={this.onClose2}>
            <MdClose />
          </div>
          <div className="sidebar_cover">
            <span className="sidebar_profile">
              <img src={this.state.userDetail?.photo} alt="profile"></img>
            </span>
            <Link to="/account">
              <h4>Profile settings</h4>
            </Link>
          </div>
        </Drawer>
      </div>
    );
  }
}
export default withRouter(HeaderCustomer);
