import { notification } from "antd";
import { SET_ERRORS } from "../actions/commonActionType";

const initialState = {};

export default function (state = initialState, action) {
  console.log(action, "actions check");
  switch (action.type) {
    case SET_ERRORS:
      notification.open({
        message: action.payload.title ? action.payload.title : "Failed",
        description: action.payload.message,
      });
      return action.payload;
    default:
      return state;
  }
}
