import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Compare from "../assets/car_compare.svg";
import { API_URL } from "../actions/urlConfig";
import axios from "axios";
import { Empty } from "antd";
import SemipolarLoading from "react-loadingg/lib/SemipolarLoading";

const SearchModal = () => {
  const [query, setquery] = useState("");
  const [forum, setforum] = useState([]);
  const [service, setservice] = useState([]);
  const [product, setproduct] = useState([]);
  const [vehicle, setvehicle] = useState([]);
  const [radio, setradio] = useState("");
  const [loader, setloader] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(query);
      axios
        .get(`${API_URL}/general/search/?search=${query}&badge=${radio}`)
        .then((res) => {
          console.log("search values", res.data, query);
          setservice(res?.data?.data?.services);
          setproduct(res?.data?.data?.products);
          setvehicle(res?.data?.data?.vehicles);
          setforum(res?.data?.data?.forums);
          setloader(false);
        });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const searchGen = (value, radio_val) => {
    setloader(true);
    axios
      .get(`${API_URL}/general/search/?search=${query}&badge=${radio_val}`)
      .then((res) => {
        console.log("search values", res.data, query);
        setservice(res?.data?.data?.services);
        setproduct(res?.data?.data?.products);
        setvehicle(res?.data?.data?.vehicles);
        setforum(res?.data?.data?.forums);
        setloader(false);
      });
  };

  return (
    <div className="search_gen_wrap">
      <div className="search_modal">
        <input
          value={query}
          placeholder="Search"
          onChange={(e) => setquery(e.target.value)}
          autoFocus
        />
        <CloseOutlined onClick={() => setquery("") || searchGen("")} />
      </div>
      <div className="search_second">
        <div
          className="search_second_cover"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div id="search_data">
            <p>Refine search</p>
            <div className="search_filter">
              <span
                onClick={() => setradio("cars") || searchGen(query, "cars")}
                className={radio === "cars" ? "selected_search_radio" : ""}
              >
                Cars
              </span>
              <span
                onClick={() =>
                  setradio("service") || searchGen(query, "service")
                }
                className={radio === "service" ? "selected_search_radio" : ""}
              >
                Services
              </span>
              <span
                onClick={() =>
                  setradio("product") || searchGen(query, "product")
                }
                className={radio === "product" ? "selected_search_radio" : ""}
              >
                Products
              </span>
              <span
                onClick={() => setradio("forum") || searchGen(query, "forum")}
                className={radio === "forum" ? "selected_search_radio" : ""}
              >
                Community Posts
              </span>
            </div>
          </div>
          {loader ? (
            <div className="empty_search">
              <SemipolarLoading style={{ margin: "unset" }} color="#F05C2D" />
            </div>
          ) : forum?.length === 0 &&
            service?.length === 0 &&
            vehicle?.length === 0 &&
            product?.length === 0 ? (
            <div className="empty_search">
              <Empty />
            </div>
          ) : (
            <div className="search_content">
              {forum?.length !== 0 &&
                forum?.length !== undefined &&
                forum.map((res) => (
                  <Link to={`/forum/thread/${res.id}`}>
                    <div className="search_content_row">
                      <span>{res.title}</span>
                      <span className="search_type">Community Posts</span>
                    </div>
                  </Link>
                ))}
              {vehicle?.length !== 0 &&
                vehicle?.length !== undefined &&
                vehicle.map((res) => (
                  <Link to={`/comparison/${res.id}/${res.model}`}>
                    <div className="search_content_row">
                      <span id="vehicle">
                        {res.brand_name}-{res.model_name}
                      </span>
                      <span className="search_type">Vehicle</span>
                    </div>
                  </Link>
                ))}
              {service?.length !== 0 &&
                service?.length !== undefined &&
                service.map((res) => (
                  <Link to={`/service-main/${res.id}`}>
                    <div className="search_content_row">
                      <span>{res.name}</span>
                      <span className="search_type">Services</span>
                    </div>
                  </Link>
                ))}
              {product?.length !== 0 &&
                product?.length !== undefined &&
                product.map((res) => (
                  <Link to={`/product-main/${res.id}`}>
                    <div className="search_content_row">
                      <span>{res.name}</span>
                      <span className="search_type">Products</span>
                    </div>
                  </Link>
                ))}
            </div>
          )}
        </div>
        <div className="search_compare">
          <Link to="/search-vehicle">
            <img alt="" src={Compare}></img>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
