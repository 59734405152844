import React, { Suspense } from "react";
import "./App.css";
import "./Store.css";
import "./Tab.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Location from "./Components/Location/Location.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "draft-js/dist/Draft.css";
import { SemipolarLoading } from "react-loadingg";


const Signin = React.lazy(() => import("./Auth/SignIn"));
const BottomNavbar = React.lazy(() => import("./BottomNavbar"));
const Address = React.lazy(() => import("./Auth/Address"));
const Feedback = React.lazy(() => import("./FeedBack"));
const Signup = React.lazy(() => import("./SignupForm"));
const Account = React.lazy(() => import("./Account/AccountMain"));
const Home = React.lazy(() => import("./Home"));
const ForumHome = React.lazy(() => import("./Forum/ForumHome"));
const NewsExpanded1 = React.lazy(() => import("./Forum/NewsExpand1"));
const otp = React.lazy(() => import("./Auth/otpVerify"));
const ForumList = React.lazy(() => import("./Forum/ForumList"));
const ThreadList = React.lazy(() => import("./Forum/ThreadList"));
const MobileSearch = React.lazy(() => import("./Components/MobSearch"));
const TrendingExp = React.lazy(() => import("./Forum/Card/TrendingExp"));
const RichText = React.lazy(() =>
  import("./Forum/Card/Upload/CreateThreadRichText")
);
const EditForum = React.lazy(() => import("./Forum/Card/Upload/EditRichText"));

const Service = React.lazy(() => import("./Services/ServiceMain"));
const Timeline = React.lazy(() => import("./Services/ServiceStatus"));
const Cart = React.lazy(() => import("./Components/Cart"));
const SelectAddress = React.lazy(() => import("./Components/SelectAddress"));
const SelectVehicle = React.lazy(() =>
  import("./Components/VehicleDetails/SelectVehicle")
);
const ServiceList = React.lazy(() => import("./Services/Services"));
const ProductList = React.lazy(() => import("./Components/Products"));
const ModelSelect = React.lazy(() =>
  import("./Components/VehicleDetails/SelectVehicle")
);

const VehicleMain = React.lazy(() =>
  import("./Components/VehicleDetails/VehicleMain")
);
const ServiceCategories = React.lazy(() =>
  import("./Services/Services/serviceList")
);
const Product = React.lazy(() =>
  import("./Components/Products/Expanded/ProductMain")
);
const InsuranceData = React.lazy(() =>
  import("./Components/Insurance/Expanded/InsuranceMain")
);
const Comparson = React.lazy(() => import("./Components/Comparison"));
const ListData = React.lazy(() => import("./Components/Insurance/ListData"));
const PolicyType = React.lazy(() =>
  import("./Components/Insurance/PolicyType")
);
const Bzns = React.lazy(() => import("./Components/Insurance/BznsType"));
const PlanSelect = React.lazy(() =>
  import("./Components/Insurance/PlanSelect")
);
const DetailedInsurance = React.lazy(() =>
  import("./Components/Insurance/DetailedInsurance")
);
const Search = React.lazy(() => import("./Components/Search"));
const Subscription = React.lazy(() => import("./Components/Subscription"));
const ComparisonExpanded = React.lazy(() =>
  import("./Components/Comparison/Screen")
);
const Landing = React.lazy(() => import("./Landing"));
const Invitation = React.lazy(() => import("./Account/Invitation"));
const ServiceStatus = React.lazy(() => import("./Services/ServiceStatus"));

const SelectDealer = React.lazy(() =>
  import("./Components/Comparison/TestDrive/SelectDealer")
);
const DealerBook = React.lazy(() =>
  import("./Components/Comparison/TestDrive/DealerBook")
);
const DealerTermsCon = React.lazy(() =>
  import("./Components/Comparison/TestDrive/DealerTermsCon")
);
const DealerResult = React.lazy(() =>
  import("./Components/Comparison/TestDrive/DealerResult")
);
const DealerReview = React.lazy(() =>
  import("./Components/Comparison/TestDrive/DealerReview")
);
const BuyCarDash = React.lazy(() =>
  import("./Components/Comparison/BuyCar/BuyCarDash")
);
const BuyCarSelectDealer = React.lazy(() =>
  import("./Components/Comparison/BuyCar/BuyCarSelectDealer")
);
const BuyCarDealerBook = React.lazy(() =>
  import("./Components/Comparison/BuyCar/BuyCarDealerBook")
);
const BuyCarDealerTermsCon = React.lazy(() =>
  import("./Components/Comparison/BuyCar/BuyCarDealerTermsCon")
);
const BuyCarBookingFee = React.lazy(() =>
  import("./Components/Comparison/BuyCar/Booking/BuyCarBookingFee")
);
const BuyCarHome = React.lazy(() =>
  import("./Components/Comparison/BuyCar/BuyCarHome/BuyCarHome")
);
const BuyCarUploadDocs = React.lazy(() =>
  import("./Components/Comparison/BuyCar/UploadDocs/BuyCarUploadDocs")
);
const BuyCarInsuranceLoan = React.lazy(() =>
  import("./Components/Comparison/BuyCar/InsuranceLoan/BuyCarInsuranceLoan")
);
const BuyCarPaymentDetails = React.lazy(() =>
  import("./Components/Comparison/BuyCar/PaymentDetails/BuyCarPaymentDetails")
);
const BuyCarDeliverCar = React.lazy(() =>
  import("./Components/Comparison/BuyCar/DeliverCar/BuyCarDeliverCar")
);
const AccountExpanded = React.lazy(() =>
  import("./Components/ServiceHistory/AccountExpanded")
);


const TyreExpanded = React.lazy(() =>
  import("./Components/TyreExpanded/TyreExpanded")
);

const TyreDetails = React.lazy(() =>
  import("./Components/TyreDetails/TyreDetails")
);

function App() {
  const token = localStorage.getItem("accessToken");
  console.log(token);
  return (
    <div
      className="App"
      style={{
        backgroundColor:
          "linear-gradient(144.37deg, rgba(240, 92, 45, 0.08) 0%, rgba(255, 255, 255, 0) 60.15%), #121212",
      }}
    >
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "10vh",
                height: "100vh",
              }}
            >
              <SemipolarLoading color="#F05C2D" />
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Landing}></Route>
            <Route exact path="/home" component={Home} />
            <Route
              exact
              path="/invite/referral/:id"
              component={Invitation}
            ></Route>
            <Route exact path="/sign-in" component={Signin} />
            <Route exact path="/map" component={Location} />
            <Route exact path="/service-main/:id" component={Service} />
            <Route exact path="/product-main/:id" component={Product} />
            <Route exact path="/service-timeline" component={Timeline} />
            <Route exact path="/signup-address" component={Address} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/address-select" component={SelectAddress} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/expanded" component={AccountExpanded} />
            <Route exact path="/productslist" component={ProductList} />
            <Route exact path="/forum" component={ForumHome} />
            <Route exact path="/service/:id" component={ServiceList} />
            <Route exact path="/modelSelect" component={ModelSelect} />
            <Route exact path="/vehicle-main" component={VehicleMain} />{" "}
            <Route exact path="/insurance" component={InsuranceData} />
            <Route exact path="/tyreexpanded" component={TyreExpanded} />
            <Route exact path="/tyredetails/:id" component={TyreDetails} />
            
            <Route
              exact
              path="/service-categories"
              component={ServiceCategories}
            />
            <Route
              exact
              path="/forum/thread/:content"
              component={NewsExpanded1}
            />
            <Route
              exact
              path="/forum/thread/trendin/:content"
              component={TrendingExp}
            />
            <Route exact path="/sign-in/verify" component={otp} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/forum/forumlist" component={ForumList} />
            <Route exact path="/forum/forumthreadlist" component={ThreadList} />
            <Route exact path="/search" component={MobileSearch} />
            <Route exact path="/forum/create/new" component={RichText} />
            <Route exact path="/forum/edit/:new" component={EditForum} />
            <Route exact path="/selectvehicle" component={SelectVehicle} />
            <Route exact path="/comparison/:id/:model" component={Comparson} />
            <Route exact path="/list-data" component={ListData} />
            <Route exact path="/policy" component={PolicyType} />
            <Route exact path="/bussiness" component={Bzns} />
            <Route exact path="/plan-select" component={PlanSelect} />
            <Route exact path="/search-vehicle" component={Search} />
            <Route exact path="/subscription/:id" component={Subscription} />
            <Route exact path="/service-status/:id" component={ServiceStatus} />
            <Route
              exact
              path="/comparison-data"
              component={ComparisonExpanded}
            />
            <Route
              exact
              path="/detailed-insurance"
              component={DetailedInsurance}
            />
            {/* test drive */}
            <Route
              exact
              path="/comparison/selectdealer/:id/:model"
              component={SelectDealer}
            />
            <Route
              exact
              path="/comparison/dealerbook/:id/:dealerId"
              component={DealerBook}
            />
            <Route
              exact
              path="/comparison/termsandconditions/:id/:dealerId"
              component={DealerTermsCon}
            />
            <Route
              exact
              path="/comparison/dealerresult/:id/:dealerId"
              component={DealerResult}
            />
            <Route
              exact
              path="/comparison/dealerreview/:id/:dealerId/:testDriveId"
              component={DealerReview}
            />
            {/* buy car */}
            <Route
              exact
              path="/buycar/dashboard/:carID/:modelID"
              component={BuyCarDash}
            />
            <Route
              exact
              path="/buycar/selectdealer/:carID/:modelID"
              component={BuyCarSelectDealer}
            />
            <Route
              exact
              path="/buycar/bookdealer/:carID/:modelID/:dealerID"
              component={BuyCarDealerBook}
            />
            <Route
              exact
              path="/buycar/termsandconditions/:carID/:modelID"
              component={BuyCarDealerTermsCon}
            />
            <Route
              exact
              path="/buycar/bookingfee/:carID/:modelID/:dealerID"
              component={BuyCarBookingFee}
            />
            <Route
              exact
              path="/buycar/home/:carID/:modelID/:dealerID"
              component={BuyCarHome}
            />
            <Route
              exact
              path="/buycar/uploaddocs/:carID/:modelID/:dealerID"
              component={BuyCarUploadDocs}
            />
            <Route
              exact
              path="/buycar/insuranceandloan/:carID/:modelID/:dealerID"
              component={BuyCarInsuranceLoan}
            />
            <Route
              exact
              path="/buycar/paymentdetails/:carID/:modelID/:dealerID"
              component={BuyCarPaymentDetails}
            />
            <Route
              exact
              path="/buycar/delivercar/:carID/:modelID/:dealerID"
              component={BuyCarDeliverCar}
            />
          </Switch>
          <BottomNavbar />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
