import React, { Component } from "react";
import L from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import carImg from "../../assets/Car-icon.png";
import petrol_shop from "../../assets/petrol.svg";
import tire_shop from "../../assets/tire_shop.svg";
import ev_station from "../../assets/ev_station.svg";
import car from "../../Images/car_map.png";
import Header from "../../Navigation/HeaderCustomer";
import { API_URL } from "../../actions/urlConfig";
import axios from "axios";
import { Drawer, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ReactLeafletSearch from "react-leaflet-search";

const options = [
  { label: "Petrol", value: "petrol" },
  { label: "Diesel", value: "diesel" },
];
class Location extends Component {
  state = {
    carIcon: {
      lat: 9.9921,
      lng: 76.3019,
    },
    carIcon2: {
      lat: 10.0014,
      lng: 76.3101,
    },
    carIcon3: {},
    zoom: 13,
    stores: [],
    lat: "",
    lon: "",
    visible: false,
    default: "flex",
    petrol: "none",
    fuel_price: [],
    fuel_selected: "petrol",
    optionsTowns: [],
    searchQuery: "",
    searching: false,
    fuel_height: 267,
  };
  carIcon = L.icon({
    iconUrl: carImg,
    iconSize: [40], // size of the icon
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -6], // point from which the popup should open relative to the iconAnchor
  });

  carIcon2 = L.icon({
    iconUrl: car,
    iconSize: [40], // size of the icon
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -6], // point from which the popup should open relative to the iconAnchor
  });
  componentDidMount() {
    axios.get(`${API_URL}/users/store_profile_list/`).then((res) => {
      console.log(res, "stores");
      this.setState({ stores: res.data.data });
    });

    axios.get(`${API_URL}/general/fuel_price/1/`).then((res) => {
      console.log(res, "stores");
      this.setState({ fuel_price: res.data.data }, () => {
        this.state.fuel_price.forEach((element) =>
          this.state.optionsTowns.push(element.townname)
        );
      });
    });

    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      const carIcon3 = {
        lat: lat,
        long: long,
      };

      this.setState({ lat, lon: long, carIcon3 });
    });
  }

  onClose = () => {
    this.setState({
      visible: false,
      petrol: "none",
      default: "flex",
      fuel_height: 267,
    });
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  render() {
    console.log(this.state.lat, "towns list");

    const positionCarIcon = [this.state.lat, this.state.lon];

    return (
      <div className="main" style={{ background: "#141414" }}>
        <Header />

        <div className="map-sos">
          <h3>Map</h3>
          {/* <Menu onClick={this.showDrawer} className="map_drawer" /> */}
          {/* <button >OPen</button> */}
          <div className="nearby_sos">
            <span>
              <a
                href={`https://www.google.com/maps/search/petrol+station/@${this.state.lat},${this.state.lon}&&z=3`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="" src={petrol_shop}></img>
                <h6>Near by Pumps</h6>
              </a>
            </span>
            <span>
              <a
                href={`https://www.google.com/maps/search/tyre+shop/@${this.state.lat},${this.state.lon}&&z=3`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="" src={tire_shop}></img>
                <h6>Near by Tyre repair</h6>
              </a>
            </span>
            <span>
              <a
                href={`https://www.google.com/maps/search/ev+charging+stations/@${this.state.lat},${this.state.lon}&&z=3`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="" src={ev_station}></img>
                <h6>EV Charging</h6>
              </a>
            </span>
            <span onClick={() => this.setState({ visible: true })}>
              <a >
                <img alt="" src={petrol_shop}></img>
                <h6>Fuel Prices</h6>
              </a>
            </span>
          </div>
          <Map
            className="map transform"
            id="map"
            center={positionCarIcon}
            zoom={this.state.zoom}
            setView={true}
            animate={true}
          >
            <TileLayer
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
            />
            <ReactLeafletSearch
              // onChange={this.location}
              inputPlaceholder="Search your city"
              position="topleft"
            />
            <Marker
              position={[this.state.lat, this.state.lon]}
              icon={this.carIcon2}
            ></Marker>

            {this.state.stores.map((res) => {
              if (res.location !== null) {
                const value = res.location;
                const value2 = value?.replace("SRID=4326;POINT", "");
                const value3 = value2?.replace(/[()]/g, "");
                var splited = value3?.split(/(\s+)/);

                return (
                  <Marker
                    position={[splited[2], [splited[4]]]}
                    icon={this.carIcon}
                  >
                    <Popup>
                      {res.name} <br /> {res.phone}
                    </Popup>
                  </Marker>
                );
              }
            })}
          </Map>
          {/* <div className="sos" style={{ display: this.state.default }}>
            <div className="sos-content1">
              <h3>Choose automoto service</h3>
            </div>
            <div className="nearby_sos">
              <span>
                <a
                  href={`https://www.google.com/maps/search/petrol+station/@${this.state.lat},${this.state.lon}&&z=3`}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img src={petrol_shop}></img>
                  <h6>Near by Pumps</h6>
                </a>
              </span>
              <span>
                <a
                  href={`https://www.google.com/maps/search/tyre+shop/@${this.state.lat},${this.state.lon}&&z=3`}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img src={tire_shop}></img>
                  <h6>Near by Tyre repair</h6>
                </a>
              </span>
              <span>
                <a
                  href={`https://www.google.com/maps/search/ev+charging+stations/@${this.state.lat},${this.state.lon}&&z=3`}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img src={ev_station}></img>
                  <h6>EV Charging</h6>
                </a>
              </span>
              <span
                onClick={() =>
                  this.setState({ petrol: "flex", default: "none" })
                }
              >
                <a>
                  <img src={petrol_shop}></img>
                  <h6>Fuel Prices</h6>
                </a>
              </span>
            </div>
          </div>
          <div className="sos" style={{ display: this.state.petrol }}>
            <div className="sos-content1" style={{ padding: "24px" }}>
              <span
                className="image_service_wrap"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() =>
                  this.setState({ petrol: "none", default: "flex" })
                }
              >
                <KeyboardBackspaceIcon />
                <h3 style={{ paddingLeft: "54px" }}>Fuel Prices</h3>
              </span>
              <div
                className="contactsTop contactsSearch"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <input
                  placeholder="Search Towns"
                  style={{ width: "100%", margin: "24px 0" }}
                  className="search-bar"
                  value={this.state.searchQuery}
                  onChange={(e) =>
                    this.setState({
                      searchQuery: e.target.value,
                      searching: true,
                    })
                  }
                />
                <CloseOutlined
                  style={{ margin: "24px 0" }}
                  onClick={() => {
                    this.setState({ searching: false });
                    this.setState({ searchQuery: "" });
                  }}
                />
              </div>
              <span>
                <Radio.Group
                  id="radio_btns"
                  onChange={(e) =>
                    this.setState({ fuel_selected: e.target.value })
                  }
                  optionType="button"
                  buttonStyle="solid"
                  options={options}
                  defaultValue="petrol"
                />
              </span>
              {this.state.searching ? (
                <span>
                  {" "}
                  {this.state.fuel_price
                    .filter((contact) =>
                      contact.townname
                        .toLowerCase()
                        .includes(this.state.searchQuery.toLowerCase())
                    )
                    .map((res, index) => {
                      return this.state.fuel_selected === "petrol" ? (
                        <div className="fuel_row">
                          <h4>{res.townname}</h4>
                          <h5>&#8377;{res.petrol}</h5>
                        </div>
                      ) : (
                        <div className="fuel_row">
                          <h4>{res.townname}</h4>
                          <h5>&#8377;{res.diesel}</h5>
                        </div>
                      );
                    })}
                </span>
              ) : (
                <span>
                  {this.state.fuel_price.map((res) =>
                    res.townname === "Thiruvananthapuram" &&
                    this.state.fuel_selected === "petrol" ? (
                      <div className="fuel_row">
                        <h4>{res.townname}</h4>
                        <h5>&#8377;{res.petrol}</h5>
                      </div>
                    ) : res.townname === "Thiruvananthapuram" &&
                      this.state.fuel_selected === "diesel" ? (
                      <div className="fuel_row">
                        <h4>{res.townname}</h4>
                        <h5>&#8377;{res.diesel}</h5>
                      </div>
                    ) : null
                  )}
                </span>
              )}
            </div>
          </div> */}
          <Drawer
            placement="right"
            onClose={this.onClose}
            visible={this.state.visible}
            height={this.state.fuel_height}
            size={"large"}
            id="fuel_price"
          >
            <div className="sos_mob">
              <div
                className="sos-content1"
                style={{
                  display: "flex",
                  padding: "24px",
                  flexDirection: "column",
                }}
              >
                <span
                  className="image_service_wrap"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h3 style={{ paddingLeft: "54px" }}>Fuel Prices</h3>
                </span>
                <div
                  className="contactsTop contactsSearch"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    placeholder="Search Towns"
                    style={{ width: "100%", margin: "24px 0" }}
                    className="search-bar"
                    value={this.state.searchQuery}
                    onChange={(e) =>
                      this.setState({
                        searchQuery: e.target.value,
                        searching: true,
                      })
                    }
                  />
                  <CloseOutlined
                    style={{ margin: "24px 0" }}
                    onClick={() => {
                      this.setState({ searching: false });
                      this.setState({ searchQuery: "" });
                    }}
                  />
                </div>
                <span>
                  <Radio.Group
                    id="radio_btns"
                    onChange={(e) =>
                      this.setState({ fuel_selected: e.target.value })
                    }
                    optionType="button"
                    buttonStyle="solid"
                    options={options}
                    defaultValue="petrol"
                  />
                </span>
                {this.state.searching ? (
                  <span>
                    {" "}
                    {this.state.fuel_price
                      .filter((contact) =>
                        contact.townname
                          .toLowerCase()
                          .includes(this.state.searchQuery.toLowerCase())
                      )
                      .map((res, index) => {
                        return this.state.fuel_selected === "petrol" ? (
                          <div className="fuel_row">
                            <h4>{res.townname}</h4>
                            <h5>&#8377;{res.petrol}</h5>
                          </div>
                        ) : (
                          <div className="fuel_row">
                            <h4>{res.townname}</h4>
                            <h5>&#8377;{res.diesel}</h5>
                          </div>
                        );
                      })}
                  </span>
                ) : (
                  <span>
                    {this.state.fuel_price.map((res) =>
                      res.townname === "Thiruvananthapuram" &&
                      this.state.fuel_selected === "petrol" ? (
                        <div className="fuel_row">
                          <h4>{res.townname}</h4>
                          <h5>&#8377;{res.petrol}</h5>
                        </div>
                      ) : res.townname === "Thiruvananthapuram" &&
                        this.state.fuel_selected === "diesel" ? (
                        <div className="fuel_row">
                          <h4>{res.townname}</h4>
                          <h5>&#8377;{res.diesel}</h5>
                        </div>
                      ) : null
                    )}
                  </span>
                )}
              </div>
            </div>
          </Drawer>
        </div>
        {/* <input type="button" value="Click Me" id="btn_ZoomIn"></input> */}
        {/* <Demo/>
      <LocationDist/> */}
      </div>
    );
  }
}

export default Location;
