import axios from "axios";
import { SET_ERRORS } from "./commonActionType";
import { API_URL } from "./urlConfig";
import setAuthToken from "./utils/setAuthToken";

const invite = localStorage.getItem("invititation");
export const SEND_OTP_SET_NUMBER = "SEND_OTP_SET_NUMBER";
export const SET_USER_DATA = "SET_USER_DATA";
// OTP - Send OTP
export const sendOtp = (data, history) => (dispatch) => {
  localStorage.setItem("phoneNumber", data.phone);
  localStorage.setItem("countryCode", data.code);
  const formData = new FormData();
  formData.append("phone", data.phone);
  formData.append("country", data.code);
  console.log(data, "data check");
  axios
    .post(`${API_URL}/users/enter-phone/`, formData)
    .then((res) => {
      console.log(res);
      if (res.data.StatusCode === 6000) {
        history.push("/sign-in/verify/");
        dispatch({
          type: SEND_OTP_SET_NUMBER,
          payload: data.phone,
        });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: {
            message: res.data.data.message,
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

//sign up
export const signUp = (data, history) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  console.log(token, "data values");
  const formData = new FormData();
  formData.append("name", data.name);
  // formData.append("email", data.email);
  // formData.append("gender", data.gender);
  // formData.append("dob", data.dob);
  formData.append("photo", data.photo);
  axios
    .post(
      `${API_URL}/users/profile-create/customer/?latitude=${data.lat}&longitude=${data.lon}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(res, "sign up res");
      if (res.data.StatusCode === 6000) {
        window.location.replace("/vehicle-main");
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: {
            message: res.data.data.message,
          },
        });
      }
      console.log(res);
    })
    .catch((er) => {
      console.log(er.response, "error main");
    });
};

//resend otp
export const resendOtp = () => (dispatch) => {
  const phone = localStorage.getItem("phoneNumber");
  const code = localStorage.getItem("countryCode");
  const formData = new FormData();
  formData.append("phone", phone);
  formData.append("country", code);
  axios
    .post(`${API_URL}/users/resend-otp/`, formData)
    .then((res) => {
      dispatch({
        type: SEND_OTP_SET_NUMBER,
        payload: phone,
      });
      console.log(res, "resend otp");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

// OTP - Verify OTP
export const verifyOtp = (otp, history) => (dispatch, getState) => {
  console.log(history, otp, "history");
  const phone = localStorage.getItem("phoneNumber");
  const code = localStorage.getItem("countryCode");
  const formData = new FormData();
  formData.append("phone", phone);
  formData.append("country", code);
  formData.append("otp", otp);

  axios
    .post(`${API_URL}/users/verify-otp/`, formData)
    .then((res) => {
      console.log(res, "verify otp");
      if (res?.data?.data?.sign_up) {
        dispatch(setCurrentUser(res.data.data.access_token));
        window.location.replace("/home");
        // history.push("/");
      } else if (res?.data?.data?.sign_up === false) {
        history.push("/signup");
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: {
            message: res?.data?.data?.message,
          },
        });
      }
      // Save to localStorage
      localStorage.setItem("automotoUserData", JSON.stringify(res.data));
      localStorage.setItem("accessToken", res.data.data.access_token);
      // Set token to Auth header
      const { token } = res.data.data.access_token;
      setAuthToken(token);

      // Set user
    })
    .catch((error) => {
      console.log(error, "error");
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

//sign up
export const addressAdd = (data, history) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  console.log(token, "token found");
  var pincode = String(data.pincode);
  console.log(typeof pincode, "type off");
  const formData = new FormData();
  formData.append("address", data.address);
  formData.append("landmark", data.landmark);
  formData.append("postal_code", data.pincode);
  axios
    .post(
      `${API_URL}/users/profile-address-create/customer/?latitude=${data.lat}&longitude=${data.lon}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.data.StatusCode === 6000) {
        history.push("/vehicletype");
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: {
            message: res.data.data.message,
          },
        });
      }
    })
    .catch((er) => {
      console.log(er.response, "error main");
    });
};

// Set logged in user
export const setCurrentUser = (userData) => {
  return {
    type: SET_USER_DATA,
    payload: userData,
  };
};

// Log user out
export const signOut = () => (dispatch) => {
  // Clear localStorage
  localStorage.clear();

  // Remove token from axios
  setAuthToken();

  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.reload();
};
